<template>
  <v-app>
    <v-dialog
      v-model="intakes"
      max-width="500px"
      :persistent="true"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Create new Intake</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="title"
                  label="Title"
                  name="Title"
                  v-validate="'required'"
                ></v-text-field>
                <span style="color: red;" v-show="errors.has('Title')" v-if="valid">{{ errors.first('Title') }}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startdate"
                      label="Start Date"
                      prepend-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      name="Date"
                    ></v-text-field>
                    <span style="color: red;" v-show="errors.has('Date')" v-if="valid">{{ errors.first('Date') }}</span>
                  </template>
                  <v-date-picker v-model="startdate" @input="menu2 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-menu
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDate"
                      label="Start Date"
                      prepend-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="endDate" @input="menu3 = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
export default {
  name: 'CreateIntake',
  props: {
    intakes: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      menu3: false,
      startdate: '',
      endDate: '',
      title: '',
      ValidityDate: '',
      valid: true,
    }
  },
  methods: {
    close () {
      this.title = ''
      this.endDate = ''
      this.startdate = ''
      this.valid = false
      this.$emit('quitModal', false)
    },
    async validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.save()
        } else {
          // form have errors
        }
      })
    },
    async save () {
      if (this.title === '' || this.startdate === '' || this.endDate === '') {
        this.validate()
      }
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      const data = {
        title: this.title,
        startdate: this.startdate,
        endDate: this.endDate,
      }
      try {
        const res = await this.axios({
          url: 'manipulate_intake',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.$emit('getResponse', res.data)
          this.title = ''
          this.startdate = ''
          this.endDate = ''
          this.valid = false
          this.close()
        } else {
          alert('Data not saved')
        }
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else if (e.response && e.response.status === 400) {
          alert(e.response.data.messages)
          this.names = ''
          this.selected = ''
          this.valid = false
        } else {
          console.log(e)
        }
      }
    },

  },
}
</script>

<style scoped>

</style>
