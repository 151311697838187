import { render, staticRenderFns } from "./Intakes.vue?vue&type=template&id=353302c8&scoped=true&"
import script from "./Intakes.vue?vue&type=script&lang=js&"
export * from "./Intakes.vue?vue&type=script&lang=js&"
import style0 from "./Intakes.vue?vue&type=style&index=0&id=353302c8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "353302c8",
  null
  
)

export default component.exports